.q14 {
  font-size: 17px;
  border-radius: 10px;
  border: 1px solid #11111114;
  padding: 16px;
  background: #fff;
  margin: 6px;
  text-align: center;
}
.flechaback {
  color: #ffffff;
  font-size: 39px;
  margin-top: 10px;
}
p.b,
p.c {
  margin: 10px -9px 12px -9px;
}
.fondotablero {
    background-color: #01155d;
    color: #fff;
    margin-bottom: 23px !important;
    width: 100%;
    padding: 15px;
    display: inline-block;
    border-radius: 17px;
    border: 1px SOLID #ffffff40;
    font-size: 24px;
}
.sig {
  font-size: 44px;
  color: #f3faff;
  background: #2066ab;
  border-radius: 11px;
  font-family: fredoka one;
  margin-top: -48px;

  padding-bottom: 32px;
  box-shadow: 0px 0px 110px #3498db, inset 0px 0px 44px 6px #0b3077;
  text-align: center;
  margin-bottom: 41px;
  padding-top: 21px;
  border: 1px solid #fff0;
}
.significado {
  color: #ffffff;

  border-radius: 17px;
  font-family: Fredoka One, cursive;
  font-size: 19px;

  margin-top: -80px;
}
.brincojuego,
.lotojuego,
.negrita,
.quinijuego,
.titjuegos,
p.horoscopo {
  font-family: Fredoka One;
  background: #01155d;

  font-size: 18px;

  margin-bottom: -39px;

  padding-bottom: 32px;
}
.titulotablero {
  text-align: center;
  font-size: 28px;

  color: #ffffff;

  padding: 11px;
  margin-top: -35px;
  font-family: fredoka one;
}
.p4 {
  font-size: 1px;
  font-weight: 7000;
  z-index: 99999999;
  color: #304178;
  text-shadow: 0px 0px 1px #39497d;
  margin-top: 17px;
  letter-spacing: 8px;
  transform: rotate(354deg);

  margin-bottom: 176px;
}

table {
  width: 100%;
  display: table;

  border-spacing: 0;

  border: 3px solid #ffffffe6;
  margin-top: -29px;
  border-radius: 20px;
  margin-left: 4px;
  padding: 21px;
}

td.fondo_posicion1 {
  background-color: #d01f14;
  border-radius: 13px;
}
.p3 .first {
  -webkit-animation: letter-flickerz 3s linear infinite;
  animation: letter-flickerz 3s linear infinite;
}
td.fondo_posicion {
  color: #7cb342;
  font-size: 24px;
  font-family: Lato, sans-serif;
}
.p3 .p4 {
  -webkit-animation: text-flickerz 2s linear forwards;
  animation: text-flickerz 2s linear forwards;
}
.bg_bienvenida2 {
  color: #e0e0e0;
  font-size: 14px;
  text-shadow: 2px 2px 0 rgb(0 0 0 / 10%);
  padding: 0px 15px 15px 15px;
}
.pad3 {
  padding: 3px !important;
}
.elsol,
.elsolnaranja {
  -webkit-box-shadow: 0 0 100px #f9f068;
  box-shadow: 0 0 100px #ffffff;
  margin-top: -361px;
}
.elsol {
  background-color: #f4e94e;
}
.elsol,
.elsolnaranja,
.lunalunera {
  width: 150px;
  height: 150px;
  border-radius: 30px;
  border-radius: 100%;
  margin: -150px 9px 88px 94px;
  z-index: 99999999;
  width: 80 px;
  height: 80 px;
  border-radius: 100 px;
  background-color: #f0f0f0;
  box-shadow: 0 0 100px rgb(255 255 255);
}
.elsol {
  background-color: #f0f0f0;
}
.elsol,
.elsolnaranja {
  -webkit-box-shadow: 0 0 100px #f9f068;
  box-shadow: 0 0 100px rgb(255 255 255);
  margin-top: -152px;
  margin-bottom: -166px;
}
.lunalunera {
  background-color: #f0f0f0;
  -webkit-box-shadow: 0 0 100px #fff;
  box-shadow: 0 0 100px #fff;
}
.c1 {
  font-size: 14px;
  color: #00000000;
  letter-spacing: 2px;
  text-shadow: 0px 0px 3px rgb(18 41 58 / 28%);
  margin-bottom: 164px;
  z-index: 99999;
  text-align: center;
  margin-top: -152px;
}
.p1 {
  border-radius: 13px;
  border: 3px solid #203e18;
  background: #081b5d;
  -webkit-animation: border-flicker 2s linear infinite;
  animation: border-flicker 2s linear infinite;
  margin-top: -7px;
  margin-bottom: -4px;
  letter-spacing: -2.8px;
  margin: 0px 4px 0px 4px;
}
.p1 .p2 {
  -webkit-animation: text-flicker 2s linear forwards;
  animation: text-flicker 2s linear forwards;
}
.p1 .first {
  -webkit-animation: letter-flicker 3s linear infinite;
  animation: letter-flicker 3s linear infinite;
}
@-webkit-keyframes letter-flicker {
  2%,
  6%,
  11%,
  18%,
  32%,
  37%,
  40%,
  to {
    color: #ffd600;
    text-shadow: 0 0 15px #ffd600;
  }
  3%,
  9%,
  14%,
  33%,
  39% {
    color: rgba(146, 135, 14, 0.5);
    text-shadow: none;
  }
}
@keyframes letter-flicker {
  2%,
  6%,
  11%,
  18%,
  32%,
  37%,
  40%,
  to {
    color: yellow;
    text-shadow: 0 0 15px yellow;
  }
  3%,
  9%,
  14%,
  33%,
  39% {
    color: #eeff002e;
    text-shadow: none;
  }
}
@-webkit-keyframes border-flicker {
  2%,
  5%,
  7%,
  13%,
  18%,
  34%,
  54%,
  to {
    border: 3px solid #00b800;
    -webkit-box-shadow: 0 0 15px -1px #00b800, 0 0 12px -1px #00b800 inset;
    box-shadow: 0 0 15px -1px #00b800, inset 0 0 12px -1px #00b800;
  }
  3%,
  6%,
  9%,
  16%,
  22%,
  36% {
    border: 3px solid #203e18;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
@keyframes border-flicker {
  2%,
  5%,
  7%,
  13%,
  18%,
  34%,
  54%,
  to {
    border: 3px solid #00b800;
    -webkit-box-shadow: 0 0 15px -1px #00b800, 0 0 12px -1px #00b800 inset;
    box-shadow: 0 0 15px -1px #00b800, inset 0 0 12px -1px #00b800;
  }
  3%,
  6%,
  9%,
  16%,
  22%,
  36% {
    border: 3px solid #203e18;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.fondo {
  background-color: #00155d;
}
.p3 .p4 {
  -webkit-animation: text-zflicker 2s linear forwards;
  animation: text-zflicker 2s linear forwards;
}
.p3 .first {
  -webkit-animation: letter-zflicker 3s linear infinite;
  animation: letter-zflicker 3s linear infinite;
}
@keyframes letter-zflicker {
  2%,
  6%,
  11%,
  18%,
  32%,
  37%,
  40%,
  100% {
    color: #35447b;
    text-shadow: 0px 0px 5px #fff0;
  }
  3%,
  9%,
  14%,
  33%,
  39% {
    color: rgb(70 84 134);
    text-shadow: 0px 0px 5px #fff;
  }
}
.dropdown-content li {
  background: #213372;
}
.q901 {
  -webkit-animation: letter-q900 3s linear infinite;
  animation: letter-q900 3s linear infinite;
}

.q900 {
  -webkit-animation: text-q900 2s linear forwards;
  animation: text-q900 2s linear forwards;
}
.p3 .first {
  -webkit-animation: letter-q900 3s linear infinite;
  animation: letter-q900 3s linear infinite;
}
@keyframes letter-q900 {
  2%,
  6%,
  11%,
  18%,
  32%,
  37%,
  40%,
  100% {
    color: #ffffff;
    text-shadow: 0px 0px 10px #fff0;
  }
  3%,
  9%,
  14%,
  33%,
  39% {
    color: rgb(255 255 255);
    text-shadow: 0px 0px 10px #fff;
  }
}
.b,
.brincojuego,
p.c {
  color: #ffffff;
  font-family: fredoka one;
}
.kk9 {
    border-left: 0 !important;
}
.b {
    background: #00b800;
    border-bottom: 2px solid #ff0;
    border-color: #ff0;
    border-radius: 10px;
    border-style: solid;
    border-width: 0.1px 1px 6px;
    color: #ff0;
    font-size: 14.5px;
    letter-spacing: .3px;
    margin: 12px -5px 13px -8px;
    padding: 12px 0 14px;
}
.dropdown-content li > a,
.dropdown-content li > span {
  color: #fff !important;
}
svg.caret {
  fill: #ffffff26 !important;
}
p.c {
  font-size: 22px;
}
.select-dropdown.dropdown-content li.selected {
  background-color: #213372 !important;
}

p.b,
p.c {
  border-bottom: 12px solid #05cf02c2;
  margin: 17px -5px 0px -7px;
}
.row .col.s2 {
  border-left: 3px solid yellow;
}
.zz9 {
  background: #081b5d;
}
.leuco {
  font-family: fredoka one;
  font-size: 28.2px;
}
.agui {
  margin-top: -10px;
  padding: 0 !important;
}
.sabatini {
  margin-bottom: -15px;
  padding: 0 !important;
}

.carobravo {
  border-left: none !important;
}
.p2 {
  font-size: 5em;
  font-weight: 900;
  z-index: 99999999;
  margin: 29px 0px 29px 0px;
}
input.select-dropdown.dropdown-trigger {
    color: #ff0 !important;
    border-bottom: none;
    text-align: center;
    background: #ffffff21;
    border-radius: 21px;
    margin: 0;
    height: 2.8rem;
    font-weight: 500;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 46px;
    color: #fff;
    background: #00155d;
    border-color: #04a218;
    border-width: 0 0 4px;
    border-style: solid;
    text-transform: uppercase;
}
.select-wrapper .caret {
  display: none;
}
h1 {
  margin: 0.67em 0;
  line-height: 39% !important;
}

td,
th {
  padding: 3px 0;
  display: table-cell;
  text-align: left;
  border-radius: 2px;
}
.center,
.center-align,
.dropdown-content li,
.materialbox-caption,
.negrita,
.sig,
.significado,
span.badge,
table.centered tbody tr td,
table.centered thead tr th {
  text-align: center;
}
footer.page-footer {
  margin-top: 20px;
  padding-top: 20px;
  background-color: #62b232;
}
.cardefe,
.negrita {
  text-transform: uppercase;
}
.brincojuego,
.lotojuego,
.negrita,
.quinijuego,
.titjuegos,
p.horoscopo {
  font-family: Fredoka One;
}
tr {
  border-bottom: 0;
}

.aspen {
    background: #04a218;
    color: #ffffff !important;
    font-size: 18.1px;
    text-align: center;
    text-shadow: none;
    border: 1.7px solid #ffff00;
    margin: 12px -8px 0px -6px;
    font-family: "FREDOKA ONE";
    letter-spacing: -0.2px;
    border-radius: 100%;
    padding: 3px;
}
.sigbg3 {
  background: #3498db;
}
.sigbg1,
.sigbg2,
.sigbg3,
.sigbg4,
.sigbg5,
.sigbg6,
.sigbg7,
.sigbg8,
.sigbg9,
.sigbg10 {
  padding: 6px;
  border-radius: 100%;
  width: 80px;
  box-shadow: 0px 0px 110px #3498db, inset 0px 0px 44px 6px #3498db;
  background: #3498db;
  border: 1px solid #3498db;
}

.mar2 {
  background: #00155d;
  padding: 0px 0px 0px 0px !important;
  border-radius: 0px 0px 10px 10px;
}
.mar3 {
  border-bottom: 9px solid #04a218;
  margin-top: 7px;
  margin-bottom: 10px;
  padding-bottom: 38px;
}
.mar4 {
  font-size: 18px;
  padding: 0;
  margin-top: 0px;
  border-radius: 10px;
  font-weight: 800;
  font-size: 21.4px;
  color: YELLOW !important;
}
.mar5 {
  font-size: 11px;
  padding: 3px;
  margin: 2px 0px;
  color: #ffffff;
  font-weight: 800;
  border-bottom: 12px solid #05cf02c2;
}
.mar6 {
  font-size: 14px;
  padding: 3px;
  margin: 0px -5px 0px 1px;
  color: #01b682;
  font-weight: 800;
  border-bottom: 12px solid #05cf02c2;
}
.mar7 {
  font-size: 14px;
  padding: 3px;
  margin: 4px 2px;
  color: #3cc79f;
  font-weight: 800;
  border-bottom: 12px solid #05cf02c2;
}
.mar8 {
  font-size: 14px;
  padding: 3px;
  margin: 4px 2px;
  color: #00b682;
  font-weight: 800;
  border-bottom: 12px solid #05cf02c2;
}
.mar9 {
  padding-bottom: 9px;
  color: #ffd600;
  font-weight: 800;
  border-bottom: 12px solid #ffd600;
  margin-top: 4px;
}
.mar10 {
  margin-top: -12px;
  font-weight: 700;
  border: 0px SOLID #ffffff;
  margin-top: 32px;
}
.modal.open {
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  top: 0% !important;
}
.q15 {
  font-weight: 900;
  color: #ffd600;
  font-size: 17px;
  text-align: center;
  margin-bottom: 18px;
  font-size: 21px;
}
.aguirre {
  color: #ffd600;
  vertical-align: -6px;
  width: 100%;
  font-size: 40px;
}
.leuco47 {
  font-weight: 800;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  margin-bottom: 18px;
  font-size: 21px;
}
.q17 {
  color: #00155d;
  font-weight: 700;
  font-size: 17px;
}
.q16 {
  margin-top: -15px;
  font-weight: 400;
  color: #00155d;
  font-size: 16px;
  letter-spacing: -0.3px;
}

.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
  font-size: inherit;
}
